<template>
  <el-dialog
    title="复制权限"
    :visible.sync="dialogTableVisible"
    width="20%"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onCloseDialog('formRules')"
  >
    <el-form
      ref="formRules"
      :model="formData"
      :rules="formRules"
      size="small"
      label-width="auto"
    >
      <el-form-item label="源账号" prop="sourceManagerGuid">
        <el-select
          v-model="formData.sourceManagerGuid"
          placeholder="请选择源账号"
        >
          <el-option
            v-for="(item, index) in tableData"
            :key="item.ManagerGuid"
            :label="item.ManagerAdmin"
            :value="item.ManagerGuid"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="要复制的权限" prop="powerClass">
        <el-select v-model="formData.powerClass" placeholder="请选择目标账号">
          <el-option
            v-for="(item, index) in roleList"
            :key="item.index"
            :label="item.PowerClass"
            :value="item.PowerClass"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onCloseDialog('formRules')">取 消</el-button>
      <el-button type="primary" @click="submitForm('formRules')"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import Management from "@/api/management.js";
import System from "@/api/system.js";

export default {
  data() {
    return {
      formData: {
        sourceManagerGuid: "",
        targetManagerGuids: [],
        powerClass: "",
      },
      formRules: {
        sourceManagerGuid: [
          { required: true, message: "请选择源账号", trigger: "change" },
        ],
        powerClass: [
          { required: true, message: "请选择权限", trigger: "change" },
        ],
      },
      tableData: [],
      roleList: [],
      dialogTableVisible: false,
    };
  },
  methods: {
    async initPowerData() {
      try {
        let { data } = await Management.initPowerData();
        this.roleList = [{ PowerClass: "全部权限" }, ...data];
        // console.log(data);
      } catch (err) {
        console.log(err);
      }
    },

    onCloseDialog(formName) {
      this.$refs[formName].resetFields();
      this.dialogTableVisible = false;
    },

    openCopyPermissions(tableData, selectRoleData) {
      this.formData.targetManagerGuids = selectRoleData.map(
        (item) => item.ManagerGuid
      );
      this.tableData = JSON.parse(JSON.stringify(tableData));
      // console.log(this.targetManagerGuids);
      this.initPowerData();
      this.dialogTableVisible = true;
    },

    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          // console.log(this.formData);
          try {
            let { errcode } = await System.copyManagerPower(
              {...this.formData, powerClass: this.formData.powerClass == "全部权限" ? "" : this.formData.powerClass}
            );
            if (errcode == 0) {
              this.$message.success("复制成功");
              this.onCloseDialog("formRules");
            }
          } catch (err) {
            console.log(err);
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style></style>
